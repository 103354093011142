<template>
  <div class="box">
    <div class="box-title">Рейтинг точек</div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
